import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const GivingTuesdayPage = () => {
  return (
    <Layout>
      <SEO 
        title="Giving Tuesday | Podpora paliativní péče" 
        description="V rámci Giving Tuesday podporujeme paliativní ambulanci Cesty domů. Pomáháme, aby více lidí mohlo důstojně dožít svůj život doma mezi blízkými."
      />
      
      <HeroSection>
        <h1>Podporujeme důstojné umírání doma 🧡</h1>
        <p className="subtitle">Celý prosinec darujeme 10 % ze zisku na podporu paliativní ambulance Cesty domů</p>
      </HeroSection>

      <MainContent>
        <div className="container">
          <div className="content-block">
            <h2>Proč podporujeme paliativní péči?</h2>
            <p>Jako pohřební služba vnímáme, že lidé často neumírají tak, jak by si přáli. Přestože by 80 % preferovalo zemřít doma, realita je opačná a k většině úmrtí dochází o samotě a v nemocnici. Kapacita hospicových služeb je stále nedostatečná.</p>
          </div>

          <div className="content-block">
            <h2>Naše podpora v rámci Giving Tuesday</h2>
            <p>V Goodbye.cz jsme se rozhodli celý prosinec dávat 10 % ze zisku na podporu paliativní ambulance organizace Cesta domů. Podporujeme službu Nemocnice doma, která poskytuje zdravotní a podpůrnou péči klientům, kteří se z kapacitních důvodů nedostali k možnosti domácího hospice. <a href="https://www.cestadomu.cz/" target="_blank" rel="noopener noreferrer">Více o Cestě domů</a></p>
          </div>

          <div className="content-block">
            <h2>O organizaci Cesta domů</h2>
            <p>Cesta domů umožňuje péči o nevyléčitelně nemocné a umírající dospělé i děti v jejich domácím prostředí a nabízí podporu jejich blízkým. Usiluje o zmírnění tabu a rozšíření vědomí, že umírání je důležitá součást života a že život je životem od začátku až do konce.</p>
          </div>

          <CTASection>
            <div className="cta-container">
              <h2>Přidejte se k nám</h2>
              <p>I vy můžete pomoci rozšířit dostupnost paliativní péče pro více lidí v České republice.</p>
              
              <div className="cta-buttons">
                <a 
                  href="https://www.darujme.cz/projekt/1207045"
                  className="donate-button primary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Darovat Cestě domů
                </a>
                <a 
                  href="https://www.givingtuesday.cz/"
                  className="learn-button secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Více o Giving Tuesday
                </a>
              </div>
            </div>
          </CTASection>
        </div>
      </MainContent>
    </Layout>
  );
};

const HeroSection = styled.section`
  background-color: #644a2a;
  color: white;
  text-align: center;
  padding: 4rem 1rem;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;

    @media (max-width: 600px) {
      font-size: 1.8rem;
    }
  }

  .subtitle {
    font-size: 1.25rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const MainContent = styled.section`
  padding: 4rem 0;
  
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
  }

  .content-block {
    margin-bottom: 3rem;
    
    h2 {
      color: #1a365d;
      margin-bottom: 1rem;
    }

    p {
      line-height: 1.6;

      a {
        color: #1a365d;
      }
    }
  }
`;

const CTASection = styled.section`
  background-color: #f7fafc;
  padding: 4rem 1rem;
  text-align: center;

  .container {
    max-width: 600px;
    margin: 0 auto;
  }

  h2 {
    color: #1a365d;
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }


  p {
    line-height: 1.6;
    margin-bottom: 1rem;
  }


  .cta-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    @media (min-width: 640px) {
      flex-direction: row;
      justify-content: center;
    }

    a {
      display: inline-block;
      padding: 0.75rem 1.5rem;
      border-radius: 0.375rem;
      font-weight: 500;
      text-decoration: none;
      transition: all 0.2s ease-in-out;

      &.primary {
        background-color: #4a5568;
        color: white;
        
        &:hover {
          background-color: #2d3748;
        }
      }

      &.secondary {
        background-color: white;
        color: #4a5568;
        border: 1px solid #4a5568;

        &:hover {
          background-color: #f7fafc;
        }
      }
    }
  }
`;


export default GivingTuesdayPage;
